@font-face {
  font-family: 'Source Sans Pro';
  src: local('SourceSansPro-Regular'), url(./fonts/SourceSansPro-Regular.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: local('SourceSansPro-SemiBold'), url(./fonts/SourceSansPro-SemiBold.ttf) format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: local('SourceSansPro-Bold'), url(./fonts/SourceSansPro-Bold.ttf) format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: local('SourceSansPro-Black'), url(./fonts/SourceSansPro-Black.ttf) format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: local('SourceSansPro-Light'), url(./fonts/SourceSansPro-Light.ttf) format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: local('SourceSansPro-Italic'), url(./fonts/SourceSansPro-Italic.ttf) format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: local('SourceSansPro-SemiBoldItalic'), url(./fonts/SourceSansPro-SemiBoldItalic.ttf) format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: local('SourceSansPro-BoldItalic'), url(./fonts/SourceSansPro-BoldItalic.ttf) format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: local('SourceSansPro-LightItalic'), url(./fonts/SourceSansPro-LightItalic.ttf) format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: local('SourceSansPro-BlackItalic'), url(./fonts/SourceSansPro-BlackItalic.ttf) format('truetype');
  font-weight: 900;
  font-style: italic;
}

body {
  margin: 0px;
  font-family: -apple-system, 'Source Sans Pro', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}